<template>
    <main id="home-news" class="page">
        <section class="jumbotron jumbotron-fluid bg-jumbotron">
            <div class="container">
                <h1 class="display-4 mb-4">新闻动态</h1>
                <p class="lead">与群众连心，与市场同行，京禧集团致力于为您带来惊喜</p>
            </div>
        </section>
        <router-view />
    </main>
</template>

<script>
export default {
    name: 'news'
};
</script>

<style lang="less">
#home-news {
    .bg-jumbotron {
        background-image: url(~@a/news/landing.jpg);
        padding-top: 10rem;
        padding-bottom: 5rem;
    }
}
</style>